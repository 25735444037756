import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Button, Col, Container, Dropdown, Modal, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBoxFull,
    faChevronLeft,
    faCross,
    faInfoCircle,
    faTimes,
    faHeart,
    faChevronUp
} from "@fortawesome/pro-light-svg-icons";
// @ts-ignore

// @ts-ignore
import * as style from './slider.module.scss';
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import '../../theme/main.module.scss';
import TopSeller from "../../components/TopSeller/TopSeller";
import Layout from "../../components/Navigation/layout";
import {Link, navigate} from "gatsby";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps, useDispatch} from "react-redux";
import {setBasketAmount, setBasketID} from '../../stores/basket/basketSlice';
import {RouteComponentProps} from "react-router";
import {StaticImage} from "gatsby-plugin-image";
import Slider from "react-slick";
import {
    amount,
    basketButton,
    category,
    company,
    companyDesc,
    companyImage,
    companyImageHolder,
    customContainer,
    dropDown,
    imageHolder,
    infoHeading,
    mainImage,
    fairPacktLogoMobile,
    moreBtn,
    mwst,
    name,
    price,
    prodDesc,
    prodImage,
    infoContainer,
    fairPacktLogo,
    vendorLogo,
    selectOptionWrapper,
    bookmarkIcon,
    bookmarkButton,
    bookmarkRemoveButton,
    modalButton,
    refPrice,
    saleBadge
} from './ProductPage.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classNames from "classnames/bind";
// @ts-ignore
import {SRLWrapper} from "simple-react-lightbox";
import ProductRequestForm from "../../components/ProductRequestForm/ProductRequestForm";
import {toast} from "react-toastify";
import "./toastifyCustom.scss";
import {setBookmarkAmount} from "../../stores/bookmark/bookmarkSlice";
import GreetingPreview from "../../components/CheckoutBasket/GreetingPreview";
import ProductPageVariantDropdown from "./ProductPageVariantDropdown";
import ProductPageVariantSlider from "./ProductPageVariantSlider";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons";
//import {faHeart} from "@fortawesome/pro-solid-svg-icons";


const mapState = ({member, basket, auth, bookmark}) => ({
    member, basket, auth, bookmark
})

const mapDispatch = {
    setBookmarkAmount,
    setBasketID,
    setBasketAmount
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends RouteComponentProps {
}

const ProductPage: FC<PropsFromRedux & Props & any> = (
    {
        pageContext: {product},
        member,
        auth,
        bookmark,
        basket,
        setBasketID,
        setBasketAmount,
        setBookmarkAmount
    }
) => {
    const [prodAmount, setProdAmount] = useState(1);
    const [showImage, setShowImage] = useState('../../240_F_126166946_vqtkYxYd6V9AVFLK0PikCa3pZXRZZu4L.jpg');
    const [vendor, setVendor] = useState<any>({});
    const [lightboxContent, setLightboxContent] = useState([]);
    const [dropAmount, setDropAmount] = useState(0);
    const [amountPrice, setAmountPrice] = useState('');
    const [productVariants, setProductVariants] = useState([]);
    const [IsVerifiedAdult, setIsVerifiedAdult] = useState(false);
    const [allBookmarks, setAllBookmarks] = useState([]);
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [hasReachedMinAmount, setHasReachedMinAmount] = useState(false);
    const [minAmount, setMinAmount] = useState('');

    useEffect(() => {
        setShowImage(product.PreviewImage)
    }, [product])

    useEffect(() => {
        setDropAmount(product.OnStock);
        if (member.MemberID) {
            if (Array.isArray(allBookmarks) && allBookmarks.length) {
                allBookmarks.map((bookmark) => {
                    if (bookmark.ID === product.ID) {
                        setIsBookmarked(true);
                        setBookmarkAmount(allBookmarks.length ?? 0)
                    }
                })
            }
        }
    }, [allBookmarks])

    useEffect(() => {
        let temp = [];
        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
            }).catch(handleApiError);
            Client.Basket.fetchAuthorized(auth.Key, basket.BasketID).then((res) => setBasketID(res.data.ID)).catch(handleApiError);
            Client.Auth.authGet(auth.Key, 'Member', member.MemberID, {}).then((res) => {
                setIsVerifiedAdult(res.data.IsVerifiedAdult);
            })
        } else {
            Client.Basket.fetch(basket.BasketID).then((res) => setBasketID(res.data.ID)).catch(handleApiError);
        }

        if (product.ProductGroupID) {
            Client.Raw.get('Product/' + product.ID + '/getPriceForAmount?amount=' + prodAmount).then((res) => {
                setAmountPrice(res.data.nicePrice)
            });

            Client.Raw.get('Product?filter[ProductGroupID]=' + product.ProductGroupID).then((res) => {
                setProductVariants(res.data);
            })
        }


        // Client.Auth.authGet(auth.Key, 'Vendor', product.VendorID).then((res) => {
        Client.Raw.get('Vendor/' + product.VendorID).then((res) => {
            setVendor(res.data);
            temp.push({
                src: product.PreviewImage,
                width: 1920,
                caption: '',
                height: 'auto'
            });
            product.ScaledImages.forEach((image) => {
                temp.push({
                    src: image,
                    width: 1920,
                    caption: res.data.CompanyName,
                    height: 'auto'
                })
            })
            setLightboxContent(temp);
        }).catch(handleApiError);

    }, [])

    useEffect(() => {
        let viewItem = {
            'event': 'view_item',
            'data': {
                'currency': 'EUR',
                'value': product.Price,
                'items': [{
                    'item_id': product.SKU,
                    'item_name': product.Title
                }]
            }
        };

        //@ts-ignore
        if(window.dataLayer) {
            //@ts-ignore
            window.dataLayer.push(viewItem);
        }
    }, [])


    function addBookmark(id: number) {
        Client.Bookmark.add(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt zur Merkliste hinzugefügt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setIsBookmarked(true);
                setBookmarkAmount(res.data.Count);


                let basketDataLayer = {
                    'cicData': {
                        'cic_product_title': product.Title,
                        'cic_product_category': product.CategoryTitle,
                        'cic_vendor_title': vendor.Title,
                        'cic_product_price': product.Price
                    },
                    'event': 'fs.productFavorited'
                };

                let gme_add_to_wishlist = {
                    'event': 'add_to_wishlist',
                    'data': {
                        'currency': 'EUR',
                        'value': product.Price,
                        'items': [{
                            'item_id': product.SKU,
                            'item_name': product.Title,
                            'item_category': product.CategoryTitle,
                            'item_category2': product.CategoryTitle2,
                            'item_category3': product.CategoryTitle3,
                            'discount': product.Sale ? product.Price - product.RefPrice : 0,
                        }]
                    }
                }

                // @ts-ignore
                window.dataLayer.push(gme_add_to_wishlist);

                //@ts-ignore
                if(window.dataLayer) {
                    //@ts-ignore
                    window.dataLayer.push(basketDataLayer);
                }

                console.log(window.dataLayer)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setIsBookmarked(false);
                setBookmarkAmount(res.data.Count)
            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function addToBasket(prodID: number) {
        Client.Basket.addItem(basket.BasketID, prodID, prodAmount).then((res) => {
            if (member.MemberID) {
                Client.Basket.fetchAuthorized(auth.Key, basket.BasketID).then((res) => setBasketAmount(res.data.TotalItems)).catch(handleApiError);
            } else {
                Client.Basket.fetch(basket.BasketID).then((res) => setBasketAmount(res.data.TotalItems)).catch(handleApiError);
            }

            let basketDataLayer = {
                'cicData': {
                    'cic_product_title': product.Title,
                    'cic_product_category': product.CategoryTitle,
                    'cic_vendor_title': vendor.Title,
                    'cic_product_price': product.Price
                },
                'event': 'fs.productToBasket'
            };

            //@ts-ignore
            if(window.dataLayer) {

                let gme_add_to_cart = {
                    'event': 'add_to_cart',
                    'data': {
                        'currency': 'EUR',
                        'value': product.Sale ? prodAmount * product.RefPrice : prodAmount * product.Price,
                        'items': [{
                            'item_id': product.SKU,
                            'item_name': product.Title,
                            'item_brand': product.VendorTitle,
                            'price': product.Price,
                            'quantity': prodAmount,
                            'item_category': product.CategoryTitle,
                            'item_category2': product.CategoryTitle2,
                            'item_category3': product.CategoryTitle3,
                            'discount': product.Sale ? product.Price - product.RefPrice : 0,
                        }]
                    }
                }

                // @ts-ignore
                window.dataLayer.push(gme_add_to_cart);

                //@ts-ignore
                window.dataLayer.push(basketDataLayer);

                console.log(product)
                console.log(window.dataLayer)
            }

            Client.Raw.get('Basket/' + basket.BasketID + '/' + 'getHasMinOrderAmount').then((res) => {
                setHasReachedMinAmount(res.data.HasReached);
                setMinAmount(res.data.MinAmount);
            }).catch(handleApiError)


            setToastMessage(res.data[0])
//            toast.info(res.data[0]);  <-- Unused because we now have the modal
            setShow(true)
            // ^- Enables modal
        }).catch(handleApiError);
    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: (product.ScaledImages.length + 1) >= 4 ? 4 : (product.ScaledImages.length + 1),
        slidesToScroll: 1,
    };

    function BasketAddedModal(props) {
        if (show) {
            return (
                <>
                    <Modal {...props} size={'md'} aria-labelledby={props.basketItem.ID} centered>
                        <Modal.Header closeButton>
                            <Modal.Title id={props.basketItem.ID}>
                                {props.basketItem.Title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col xs={12} className={'text-center pb-0 pt-0'}>
                                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                        <path className="checkmark__check" fill="none"
                                              d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                                    </svg>
                                </Col>
                                <Col xs={12} className={'text-center'}>
                                    <p>{prodAmount}x {toastMessage}</p></Col>

                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col xs={6} className={'text-left'}>
                                        <Button className={modalButton + ' w-100  mb-lg-0'}
                                                onClick={() => {
                                                    setShow(false)
                                                }}>
                                            <span>Weiter einkaufen</span>
                                        </Button>
                                    </Col>
                                    <Col xs={6} className={'text-right'}>
                                        <Button
                                            className={modalButton + ' w-100 mb-lg-0' + (!hasReachedMinAmount ? ' opacity-50 pe-none' : '')}
                                            onClick={() => {
                                                navigate('/checkout')
                                            }}>
                                            <span>Zur Bestellung</span>
                                        </Button>
                                    </Col>
                                    {!hasReachedMinAmount ? <Col xs={12} className={'text-center mt-3 mb-0'}>
                                        <span> Du hast den Mindestbestellwert von {minAmount} pro Händler noch nicht
                                            erreicht.</span>
                                    </Col> : null}

                                </Row>
                            </Container>
                        </Modal.Footer>

                    </Modal>
                </>
            )
        } else {
            return (
                <>
                </>
            )
        }
    }


    let cl = classNames.bind(style);

    // @ts-ignore
    return (
        <Layout pageTitle={product.Title + ' - fairstärkt - ' + product.CategoryTitle}
                description={product.Description ? product.Description.replace(/<[^>]*>?/gm, '') : null}
                image={product.PreviewImage}>
            <div className={"d-none d-lg-block"}>
            </div>
            <div className={customContainer}>
                <div className="text-center mb-5">
                    <a href={`/marktplatz?catid=${product.CategoryID}`} style={{textDecoration: 'none'}}>
                        <h2 className={category}> {product.CategoryTitle}</h2>
                    </a>
                    <h1 className={name}> {product.Title}</h1>
                </div>
                <Row>
                    <Col lg={6} md={12} xs={12}>
                        <>
                            <div className={imageHolder}>

                                {showImage ?
                                    <>
                                        <SRLWrapper>
                                            {/*TODO*/}
                                            <div>
                                                {product.Sale ? <span
                                                    className={"badge position-absolute translate-middle " + saleBadge}>Sale</span> : null}
                                                <img src={showImage} className={mainImage + ' img-fluid'}/>

                                                {showImage !== product.PreviewImage && showImage !== null ?
                                                    <img src={product.PreviewImage} className={prodImage + ' d-none'}/>
                                                    : <img src={""} className={prodImage + ' d-none'}/>}
                                                {product.ScaledImages.map((item) => {
                                                    return (
                                                        showImage != item ?
                                                            <img src={item} className={prodImage + ' d-none'}/>
                                                            : <img src={""} className={prodImage + ' d-none'}/>
                                                    )
                                                })}
                                            </div>
                                        </SRLWrapper>

                                    </>
                                    :
                                    <StaticImage src={"../../images/fairstaerkt_emblem_color.png"}
                                                 placeholder={"traceSVG"} alt={"background"}/>
                                }
                            </div>
                            {product.ScaledImages.length ?
                                // <CustomSlider mainImage={product.PreviewImage} items={product.ScaledImages}
                                //               setMainImage={(image) => setShowImage(image)}/>
                                // <Slider {...settings} className={"pt-4 " + cl({
                                //     slickSlider4: product.ScaledImages.length === 3,
                                //     slickSlider6: product.ScaledImages.length === 2,
                                //     slickSlider12: product.ScaledImages.length === 1 || product.ScaledImages.length > 3
                                // })}>
                                <Slider {...settings} className={"pt-4 " + style.slickSlider12}>
                                    <Col
                                        lg={12}>
                                        <img src={product.PreviewImage} className={prodImage}
                                             onClick={() => setShowImage(product.PreviewImage)}/>
                                    </Col>
                                    {product.ScaledImages.map((item) => {
                                        return (

                                            <Col
                                                lg={12}>
                                                <img src={item} className={prodImage}
                                                     onClick={() => setShowImage(item)}/>
                                            </Col>

                                        )
                                    })}

                                </Slider> :
                                null
                            }
                        </>
                    </Col>
                    <hr className={"d-block d-lg-none"}/>

                    <Col lg={6} md={12}>
                        <div className="py-4 py-lg-0 pb-lg-4">
                            <h3 className={infoHeading + " mb-0 pl-3"}>Über {product.Title}</h3>
                            <span className={prodDesc}>
                            <p dangerouslySetInnerHTML={{__html: product.Description}}>
                            </p>
                            </span>
                        </div>
                        <div>
                            {console.log(product)}
                            {product.Sale ? <span
                                    className={refPrice}>{product.RefPriceNice}</span>
                                : null}
                            <span className={price}>{product.ProductGroupID ? amountPrice : product.NicePrice}</span>
                            <p className={mwst}>inkl. MwSt. zzgl. <Link className="text-reset"
                                                                        to="/s/versand">Versandkosten</Link></p>
                        </div>
                        <div>
                            {vendor.EstimatedShipping ? <div className={'mt-2 d-flex align-items-center'}>
                                <FontAwesomeIcon icon={faChevronRight} style={{color: '#bee000'}}/>
                                <span className={'ms-2 ' + mwst}>{vendor.EstimatedShipping}</span>
                            </div> : null}
                        </div>
                        <hr/>
                        {product.OnStock ?
                            <>
                                <Row className="align-items-center">
                                    <Col lg={3}>
                                        <p className={amount}>Menge</p>
                                    </Col>
                                    <Col lg={3}>
                                        <Dropdown className={dropDown}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                {prodAmount}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {[...Array(dropAmount > 20 ? 20 : dropAmount)].map((val: number, index: number) => {
                                                    return <Dropdown.Item
                                                        onClick={() => {
                                                            setProdAmount(index + 1);
                                                            if (product.ProductGroupID) {
                                                                Client.Raw.get('Product/' + product.ID + '/getPriceForAmount?amount=' + (index + 1)).then((res) => {
                                                                    setAmountPrice(res.data.nicePrice)
                                                                });
                                                            }
                                                        }}>{index + 1}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </>
                            :
                            null
                        }

                        {product.ProductGroupID !== 0 && productVariants.length > 1 ?
                            <>
                                <ProductPageVariantDropdown product={product}
                                                            variants={productVariants}/>
                            </> : null
                        }
                        <hr/>

                        {product.OnStock ?
                            <>
                                <Row>

                                    {member.Member.BookmarkCollections ?
                                        <>
                                            <Col lg={6}
                                                 className={"d-flex justify-content-start align-items-center py-3"}>
                                                {isBookmarked ?
                                                    <>
                                                        <button className={bookmarkRemoveButton + " w-100"}
                                                                onClick={() => removeBookmark(product.ID)}>
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                className={bookmarkIcon}/>
                                                            <span className={'ps-2'}>Von Merkliste entfernen</span>
                                                        </button>
                                                    </>
                                                    :
                                                    <button className={bookmarkButton + " w-100"}
                                                            onClick={() => addBookmark(product.ID)}>
                                                <span>
                                                <FontAwesomeIcon icon={faHeart}
                                                                 className={bookmarkIcon}
                                                />Zur Merkliste hinzufügen</span>
                                                    </button>
                                                }
                                            </Col>
                                            <Col xs={12}>
                                                <hr/>
                                            </Col>
                                        </>
                                        : null
                                    }
                                    <Col lg={6} md={12} xs={12}>
                                        {(product.RequiresLegalAge && IsVerifiedAdult) || !product.RequiresLegalAge ?
                                            <Button className={basketButton + ' w-100 mb-5 mb-lg-0'}
                                                    onClick={() => {
                                                        addToBasket(product.ID);
                                                    }}>
                                                    <span><FontAwesomeIcon
                                                        icon={faBoxFull}/>Zum Warenkorb hinzufügen</span>
                                            </Button> :
                                            <span>Um dieses Produkt zu bestellen, musst du dein <a
                                                href={'/user?tab=MyData'}>Alter verifizieren.</a></span>}
                                    </Col>
                                    <Col lg={5} md={12} xs={12} className={"pt-2 pt-lg-0"}>
                                        <Button className={moreBtn + " w-100"} as={Link} to={"/marktplatz"}>
                                            <FontAwesomeIcon icon={faChevronLeft}/>
                                            Zur Übersicht
                                        </Button>
                                    </Col>
                                </Row>

                            </> : <> {member.Member.BookmarkCollections ?
                                <>
                                    <Col xs={12}
                                         className={"d-flex justify-content-start align-items-center py-3"}>
                                        {isBookmarked ?
                                            <>
                                                <FontAwesomeIcon
                                                    icon={faTimes}
                                                    className={bookmarkIcon}
                                                    onClick={() => removeBookmark(product.ID)}/>
                                                <span className={'ps-2'}>Von Merkliste entfernen</span>
                                            </>

                                            :
                                            <button className={bookmarkButton} onClick={() => () => {
                                                addBookmark(product.ID)
                                            }}>
                                <span>
                                <FontAwesomeIcon icon={faHeart}
                                                 className={bookmarkIcon}
                                />Zur Merkliste hinzufügen</span>
                                            </button>

                                        }
                                    </Col>
                                    <Col xs={12}>
                                        <hr/>
                                    </Col>
                                </>
                                : null
                            } <ProductRequestForm vendorID={product.VendorID} productName={product.Title}/></>
                        }
                    </Col>

                    <Col lg={6} md={12} className="d-flex d-lg-none flex-column pb-3 container my-5">
                        <hr/>
                        <div className={infoContainer}>
                            <Row className={"align-items-center"}>
                                <Col md={3}
                                     className={fairPacktLogoMobile + " justify-content-center align-items-center d-flex"}>
                                    <StaticImage
                                        src={'../../images/fairpackt.png'}
                                        placeholder="tracedSVG"
                                        alt="image"
                                    />
                                </Col>
                                <Col md={9} className={"align-items-center d-flex"}>
                                    <p className={'px-3 px-md-0'}><b>Fairliebt. Fairpackt. Fairsendet.</b> Nicht
                                        nur
                                        unsere Produkte sind fair zur
                                        Umwelt, sondern auch der Weg auf dem sie zu dir nach Hause kommen. Daher
                                        achten
                                        wir darauf, dass die Bestellmenge je Händler € 25,- nicht unterschreitet
                                        und der
                                        logistische und ökologische Aufwand in Relation stehen.</p>
                                </Col>
                            </Row>
                        </div>

                        <div className={infoContainer}>
                            <Row className={"align-items-center"}>
                                <Col md={3}
                                     className={"justify-content-center text-center align-items-center d-flex mb-4 mb-md-0"}>
                                    <img src={vendor.PreviewImage} className={vendorLogo}/>
                                </Col>
                                <Col md={9} className={"align-items-start flex-column d-flex"}>
                                    <div className={'px-3 px-md-0'}>
                                        <p><b>Spare Versandkosten, indem du mehr Produkte von
                                            diesem Händler
                                            bestellst.</b> Unsere faire Versandkostenpauschale zahlst du für
                                            jeden
                                            Händler,
                                            bei dem du bestellst. Je mehr Artikel du beim selben Händler
                                            bestellst,
                                            desto
                                            günstiger wird also im Verhältnis der Versand.</p>
                                        <Button className={moreBtn} as={Link}
                                                to={`/haendleruebersicht/${product.CompanyURL}`}>
                                            <p> Mehr von diesem Händler </p>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <h3 className={company + ' mt-5'}><FontAwesomeIcon icon={faInfoCircle}
                                                                           className={"icon"}/> ÜBER {product.CompanyName}
                        </h3>
                        <span>
                            <p className={companyDesc}
                               dangerouslySetInnerHTML={{__html: vendor.Description ? vendor.Description : 'Keine Beschreibung vorhanden.'}}></p>
                        <Button className={moreBtn} as={Link} to={`/haendleruebersicht/${product.CompanyURL}`}>
                            <p> Mehr von diesem Händler </p>
                        </Button>
                        </span>
                    </Col>
                </Row>
                <hr className="my-5 d-none d-lg-block"/>

                <div className={infoContainer + ' d-none d-lg-flex'}>
                    <Row className={"align-items-center"}>
                        <Col md={3} className={fairPacktLogo + " justify-content-center align-items-center d-flex "}>
                            <StaticImage
                                src={'../../images/fairpackt.png'}
                                placeholder="tracedSVG"
                                alt="image"
                            />
                        </Col>
                        <Col md={9} className={"align-items-center d-flex"}>
                            <p><b>Fairliebt. Fairpackt. Fairsendet.</b> Nicht nur unsere Produkte sind fair zur Umwelt,
                                sondern auch der Weg auf dem sie zu dir nach Hause kommen. Daher achten wir darauf, dass
                                die Bestellmenge je Händler € 25,- nicht unterschreitet und der logistische und
                                ökologische Aufwand in Relation stehen.</p>
                        </Col>
                    </Row>
                </div>

                <div className={infoContainer + ' d-none d-lg-flex'}>
                    <Row className={"align-items-center"}>
                        {vendor.PreviewImage ?
                            <Col md={3} className={"justify-content-center text-center align-items-center d-flex"}>
                                <img src={vendor.PreviewImage} className={vendorLogo}/>
                            </Col>
                            : null}
                        <Col md={vendor.PreviewImage ? 9 : 12}
                             className={"align-items-start flex-column d-flex" + (vendor.PreviewImage ? ' ' : ' text-center')}>
                            <p><b>Spare Versandkosten, indem du mehr Produkte von diesem Händler bestellst.</b> Unsere
                                faire Versandkostenpauschale zahlst du für jeden Händler, bei dem du bestellst. Je mehr
                                Artikel du beim selben Händler bestellst, desto günstiger wird also im Verhältnis der
                                Versand.</p>
                            <Button className={moreBtn} as={Link} to={`/haendleruebersicht/${product.CompanyURL}`}>
                                <p> Mehr von diesem Händler </p>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Row className={"d-none d-lg-flex"}>
                    <Col lg={12} className="d-flex flex-column pb-3 my-5">
                        <h3 className={company}><FontAwesomeIcon icon={faInfoCircle}
                                                                 className={"icon"}/> ÜBER {product.CompanyName}</h3>
                        <span>
                            <p className={companyDesc}
                               dangerouslySetInnerHTML={{__html: vendor.Description ? vendor.Description : 'Keine Beschreibung vorhanden.'}}></p>
                            <Button className={moreBtn} as={Link} to={`/haendleruebersicht/${product.CompanyURL}`}>
                            <p> Mehr von diesem Händler </p>
                            </Button>
                            </span>
                    </Col>
                </Row>
                <BasketAddedModal animation={false} show={show} basketItem={product}
                                  onHide={() => setShow(false)}/>
            </div>
            <TopSeller title="Weitere Produkte dieses Händlers" type="random" background={true}
                       categoryID={product.VendorID}/>
        </Layout>
    )
}

export default connector(ProductPage);
